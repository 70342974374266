export const range = (n) => [...Array(n).keys()]

export const stripProtocol = (url) => url.replace(/(^\w+:|^)\/\//, "")

export const YOUTUBE_LINK_REGEX = /(?<=[=\/&])[a-zA-Z0-9_\-]{11}(?=[=\/&?#\n\r]|$)/
export const SPOTIFY_TRACK_LINK_REGEX =
  /^(https:\/\/|http:\/\/)?(open.spotify.com\/track\/|spotify:track:)([a-zA-Z0-9]+)(.*)$/m

export const internalRoles = [
  "admin",
  "business_affairs",
  "staff",
  "marketing",
  "a_and_r",
  "internal",
]

export const pronounOptions = [
  { label: "He/Him", value: "he/him" },
  { label: "She/Her", value: "she/her" },
  { label: "They/Them", value: "they/them" },
  { label: "He/They", value: "he/they" },
  { label: "She/They", value: "she/they" },
  { label: "Prefer not to say", value: "prefer not to say" },
]

export const communityOptions = [
  {
    label: "Disability/disabilities (Cognitive, Intellectual, or other)",
    value: "Disability/disabilities (Cognitive, Intellectual, or other)",
  },
  { label: "Homeless/Houseless", value: "Homeless/Houseless" },
  { label: "Immigrant/Refugee", value: "Immigrant/Refugee" },
  { label: "LGBTQIA+", value: "LGBTQIA+" },
  { label: "Served in a branch of the military", value: "Served in a branch of the military" },
  { label: "None of these", value: "None of these" },
  { label: "Prefer not to say", value: "Prefer not to say" },
]

export const genderOptions = [
  { label: "Woman", value: "Woman" },
  { label: "Man", value: "Man" },
  { label: "Non-Binary", value: "Non-Binary" },
  { label: "Trans Man", value: "Trans Man" },
  { label: "Trans Woman", value: "Trans Woman" },
  { label: "Two-Spirit", value: "Two-Spirit" },
  { label: "Intersex", value: "Intersex" },
  { label: "Gender Non-Conforming", value: "Gender Non-Conforming" },
  { label: "I prefer not to say", value: "I prefer not to say" },
]

export const ethnicityOptions = [
  { label: "African American or Black", value: "African American or Black" },
  { label: "East Asian", value: "East Asian" },
  { label: "Latinx/Hispanic", value: "Latinx/Hispanic" },
  { label: "Native American or Alaskan Native", value: "Native American or Alaskan Native" },
  { label: "Native Hawaiian or Pacific Islander", value: "Native Hawaiian or Pacific Islander" },
  { label: "Middle Eastern", value: "Middle Eastern" },
  { label: "South Asian", value: "South Asian" },
  { label: "Southeast Asian", value: "Southeast Asian" },
  { label: "White/European", value: "White/European" },
  { label: "Aboriginal", value: "Aboriginal" },
  { label: "I prefer not to say", value: "I prefer not to say" },
]

export const replaceMMUrl = (url) => {
  if (url) {
    return url.replace(/(https:\/\/)?(http:\/\/)?(www\.)?(marmosetmusic\.com)(\/)?/, "/")
  }
}

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

export const resizeVimeoThumbnail = (url) => url.replace(/(_\d{3}[x]\d{3})/, "_513")

export const createVimeoSrcSet = (url) => {
  let lowres = url.replace(/(_\d{3}[x]\d{3})/, "_513")
  let hires = url.replace(/(_\d{3}[x]\d{3})/, "_1026")
  let srcSet = `${lowres} 1x, ${hires} 2x`
  return srcSet
}

export const createSrcSet = (item, sizes) => {
  let arr = []
  for (var key in item) {
    if (item[key] && item[key].hasOwnProperty("url") && sizes.hasOwnProperty(key)) {
      arr.push(`${item[key].url} ${sizes[key]}`)
    }
  }
  let srcSet = `${item.url} 1x`
  if (arr.length > 0) {
    srcSet = arr.join(", ")
  }
  return srcSet
}

export const styleHeader = () => {
  const rect = document.querySelector(".sub-hero"),
    targ = document.querySelectorAll(".hero-background")

  if (rect === null) return false

  if (Math.ceil(rect.getBoundingClientRect().top) <= 0) {
    targ.forEach((el) => el.classList.replace("hero-fixed", "hero-absolute"))
  } else {
    targ.forEach((el) => el.classList.replace("hero-absolute", "hero-fixed"))
  }
}

export const clearUrlQuery = () => {
  let url = window.location.href
  url = url.split("?")[0]
  window.history.replaceState(null, null, url)
}

export const escapeToClose = (setOpenModal) => {
  const keyListener = (e) => {
    if (e.keyCode === 27) {
      e.stopPropagation()
      setOpenModal(false)
    }
  }

  document.addEventListener("keydown", keyListener)

  return () => document.removeEventListener("keydown", keyListener)
}

export const generateUniqueKey = () => {
  let S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return S4() + S4() + "-" + S4() + "-" + S4()
}

export const addUIDs = (item) => {
  if (Array.isArray(item)) {
    item = item.map((array_item) => {
      array_item = addUIDs(array_item)
      return array_item
    })
  } else if (typeof item === "object" && item !== null) {
    if (!item.hasOwnProperty("uid")) {
      item["uid"] = generateUniqueKey()
      for (var k in item) {
        item[k] = addUIDs(item[k])
      }
    }
  }
  return item
}

const tagIsPresent = (el, key) => {
  if (!el.getAttribute("data-tags")) {
    return false
  } else if (el.getAttribute("data-tags") && el.getAttribute("data-tags").indexOf(key) < 0) {
    return false
  } else {
    return true
  }
}

export const filterItems = (filter_key) => {
  filter_key = "" ? "All" : filter_key
  let team = document.querySelector(".prismicTeam")
  let team_items = team.querySelectorAll(".prismicTeamItem[data-tags]")
  let pos = 1
  for (let i = 0; i < team_items.length; ++i) {
    let member = team_items[i]
    if (filter_key != "All" && !tagIsPresent(member, filter_key)) {
      member.setAttribute("data-position", 0)
    } else {
      member.setAttribute("data-position", pos)
      if (pos === 6) {
        pos = 4
      } else {
        pos += 1
      }
    }
  }
}
