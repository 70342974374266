import React from "react"
import { Field, ErrorMessage } from "formik"
import cx from "classnames"
import formStyles from "../styles/form.module.scss"

const TextFieldArea = (props) => {
  const {
    rows,
    cols,
    label,
    fieldName,
    inputClass,
    innerRef,
    maxLength,
    placeholder,
    showErrorBorder,
    admin,
  } = props

  return (
    <div className={formStyles.fieldRow}>
      <label htmlFor={fieldName} className={cx(formStyles.label, { [formStyles.admin]: admin })}>
        {label}
      </label>
      <Field
        component="textarea"
        innerRef={innerRef}
        rows={rows}
        cols={cols}
        maxLength={maxLength}
        name={fieldName}
        placeholder={placeholder}
        id={fieldName}
        className={cx({ [formStyles.inputError]: showErrorBorder }, inputClass)}
      />
      <ErrorMessage
        name={fieldName}
        // .error-msg is for ScrollToError component, not styling
        className={cx(formStyles.errorMsg, "error-msg")}
        component="div"
      />
    </div>
  )
}

TextFieldArea.defaultProps = {
  rows: "20",
  cols: "100",
  maxLength: "500",
  placeholder: "",
  innerRef: null,
  admin: false,
}

export default TextFieldArea
